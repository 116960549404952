body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


main{
  margin-top: 10vh;
  min-height: 80vh;
}

.paginationItemStyle {
  margin-left: 5px;
}
.paginationItemStyle .page-link {
  color: blue;
}
.paginationItemStyle .page-link:hover {
  background-color: blue;
}
.paginationItemStyle .active {
  background-color: green !important;
  border-color: green !important;
}
.paginationLinkStyle {
  background-color: #b90000;
  border-radius: 5px;
}
.paginationLinkStyle:hover {
  background-color: #772e2e;
}
.paginationLinkStyle:active {
  background-color: #772e2e;
}