.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fixed-header-table thead th{
    position: sticky;
    top: 0;
    background-color: #f8f9fa; /* Color de fondo del encabezado */
    z-index: 2; /* Asegura que el encabezado esté por encima del contenido desplazable */
}

/*
.icon-rounded{
    width: 15.5rem;
    height: 9.5rem;
    border-radius: 10px;
    padding-top: 15px;
    box-shadow: 0px 0px 5px rgba(120, 120, 120, 0.5);
}

.icon-rounded:hover{
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
    transform: scale(1.15);
}

*/

.menu-card{
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(120, 120, 120, 0.2);
    transition: all 0.3s ease;
}

.menu-card:hover{
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(120, 120, 120, 0.5);
    transform: scale(1.05);
}


.icon-rounded{
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(120, 120, 120, 0.5);
    transition: all 0.6s ease;
}

.icon-rounded:hover{
    box-shadow: 0px 0px 10px rgba(170, 170, 170, 0.5);
    border-radius: 50px;
    transform: scale(1.25) rotate(-2deg);
    filter: saturate(150%)
}

.files-card{
    background-color: #fefefe;
    border-radius: 6px;
    border: solid 1px #ddd;
    height: 55vh;
}

.folder-card{
    background-color: #fefefe;
    border-radius: 6px;
    border: solid 1px #ddd;
    height: 30vh;
}

.shadow-doc:hover{
    transform: scale(1.01);
    background-color: #fbfbfb;
}

.icon-card:hover{
    transform: scale(1.02);
}

.icon-card{
    background-color: #E6F4FF;
    border-radius: 6px;
    border: solid 1px #0198E1;
    height: auto;
    padding: 12px;
    color: #0198E1;
    font-size: 18px;
}

.ellipsis-menu{
    font-size: 20px;
    background-color: transparent;
    border-color: transparent;
}
.ellipsis-menu:hover{
    color:white;
}

.priority-box{
    width: 100%;
    border-radius: 20px;
    color:white
}
.status-circle{
    width: 20px;
    height: 20px;
    border-radius: 50px;
}

.procesos {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    &::-webkit-scrollbar{
        display: none;
    }

}

.proceso-sticky {
    height: 100px;
    position: sticky;
    top:157px;
    background-color: white;
    z-index: 999;
}

.proceso {
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.09);
    background-color: white;
    padding: 10px 25px;
    border-radius: 5px;
}

.proceso-header{
    font-weight: bold;
    display: flex;
    gap: 20px;
    cursor: pointer;
}

.proceso-docs{
    padding: 15px;
}